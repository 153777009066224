import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AppTemplateListComponent } from "./internal-website-template-list.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { LayoutModule } from "app/layout/layout.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatIconModule,
    NgxSkeletonLoaderModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule,
    MatButtonModule
  ],
  exports: [AppTemplateListComponent, MatPaginatorModule],
  declarations: [AppTemplateListComponent]
})
export class InternalWebsiteTemplateListModule {}
