import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

import { TenantPing } from '../models/tenantPing';
import { WindowRef } from './winref.service';
import { TenantUserService } from './tenantuser.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubSink } from 'subsink';
import { AppConstants } from '../settings/appconstants';
import { AppUtilsService } from './apputils.service';

@Injectable({
  providedIn: 'root',
})
export class AppLoadService {
  static _cloudName = '';
  private hostName: string;
  private hostId: string;
  private _tenantConfig: TenantPing;
  private _configSubject: BehaviorSubject<any>;
  subs = new SubSink();

  constructor(private http: HttpClient, private windowRef: WindowRef, private _tenantUserService: TenantUserService, private router: Router, private _location: Location) {
    this.hostName = this.windowRef.getHostName();
    this.hostId = this.getHostId().includes('localhost') ? _tenantUserService.getOrgIdFromStorage() : this.getHostId() == undefined ? _tenantUserService.getOrgIdFromStorage() : this.getHostId();
    // this.hostId = this.hostName
    this._tenantUserService.getOrgId();
    this._configSubject = new BehaviorSubject(this._tenantConfig);
    this.initConfig();
  }
  construct(hostId) {
    this._tenantUserService.storeOrgIdOnLocal(hostId);
    this._tenantUserService.setOrgId(hostId);
    this.setHostId(hostId);
    // this.initConfig();
    this.routeToLoggedInPages();
    // this.initializeApp();
  }

  private routeToLoggedInPages(): void {
    this._tenantUserService.initUser(this._tenantConfig.isAuthValid);
  }

  initializeApp(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      // not needed as we are not using cookies
      // this._tenantUserService.setIsAuthTokenDomain(this.isAuthTokenDomain());
      console.log('hostId is' + this.hostId);
      if (this.hostId === 'undefined') {
        resolve('result');
      } else {
        const apiUrl = `${environment.apiBaseUrl}/${AppConstants.PING_API}?name=${this.hostId}`;
        this.http
          .get(apiUrl)
          .toPromise()
          .then(
            (data: any) => {
              if (data.orgId) {
                // console.log({ data });
                this.populateTenantConfig(data);
                this._tenantUserService.setOrgId(this.getOrgId());
                if (this.validatePublicUrlsInHostName()) {
                  resolve('public_page');
                } else {
                  this._tenantUserService.initUser(this._tenantConfig.isAuthValid).then(result => {
                    resolve(result);
                  });
                }
              } else {
                if (this._tenantUserService.isSocialLogin()) {
                  this._tenantUserService.initUser(this._tenantConfig.isAuthValid).then(result => {
                    this.construct(this._tenantUserService.getOrgId());
                    resolve(result);
                  });
                } else {
                  resolve('result');
                }
              }
            },
            msg => {
              //TODO - show login dialog, but now show it with the option of orgId, where he has to enter the orgName or OrgId
              //based on whether its number or orgId we will from backend allow to login
              if (this._tenantUserService.isSocialLogin()) {
                this._tenantUserService.initUser(this._tenantConfig.isAuthValid).then(result => {
                  this.construct(this._tenantUserService.getOrgId());
                  resolve(result);
                });
              } else {
                if (msg.status == 500) {
                  alert('no valid orgId found for account' + this.hostId);
                } else {
                  resolve('result');
                }
              }
            },
          );
      }
    });
    return promise;
  }

  updateTenantConfig(isNotify) {
    const promise = new Promise((resolve, reject) => {
      // this._tenantUserService.setIsAuthTokenDomain(this.isAuthTokenDomain());
      const apiUrl = `${environment.apiBaseUrl}/${AppConstants.PING_API}?name=${this.hostId}`;
      this.http
        .get(apiUrl)
        .toPromise()
        .then(
          (data: any) => {
            if (data.orgId) {
              // console.log({ data });
              this.populateTenantConfig(data);

              resolve(this._tenantConfig);
            } else {
              reject('no valid orgId found for => ' + this.hostId);
            }
          },
          msg => {
            reject(msg);
          },
        );
    });
    return promise;
  }

  public async reping(): Promise<void> {
    console.log('reping is called');
    const orgId = this.tenantConfig.orgId;
    const apiUrl = `${environment.apiBaseUrl}/${AppConstants.PING_API}?name=${orgId}`;
    const data: any = await this.http.get(apiUrl).toPromise();
    console.log('recieved resposne from ping, populating tenant');
    this.populateTenantConfig(data, false);
  }

  get tenantConfig(): TenantPing {
    return this._tenantConfig;
  }

  /**
   * Get tenantConfigChanged
   *
   * @returns {Observable<any>}
   */
  get onTenantConfigChanged(): Observable<any> {
    return this._configSubject.asObservable();
  }

  setTenantConfig(item: any) {
    this._tenantConfig = item;
    this._configSubject.next(this._tenantConfig);
  }

  initConfig(): void {
    this._tenantConfig = {
      bankFees: '',
      bigLogo: '',
      buyButtonLabel: '',
      // calendarVisibility: "",
      cardFees: '',
      cloudName: '',
      csymbol: '',
      customDomain: '',
      email: '',
      environmentName: '',
      isAddressNeededForEvent: false,
      // isDonationPublicCheckout: false,
      isNewClient: false,
      isRegAndPurchaseOnSamePage: false,
      isShowCalenderView: false,
      isShowCourses: false,
      isShowDonation: false,
      isShowScheduleMenu: false,
      isShowStoreMenu: false,
      isSupportGrn: false,
      isShowRegistrationLink: false,
      isShowRoomName: false,
      isShowSchedule: false,
      isWaiverFormToBeSigned: false,
      logo: '',
      name: '',
      orgId: '',
      smallLogo: '',
      timezone: '',
      version: '',
      waiverFormLink: '',
      website: '',
      workshopLabel: '',
      isAuthValid: false,
      zoomApi: '',
      zoomAuthCode: '',
      clanMeetingDomain: '',
      clanMeetingSecret: '',
      seoDescription: '',
      seoKeywords: '',
      registerButtonLabel: '',
      webstate: '',
      forms: [],
      title: '',
      web: [],
      webRoutes: [],
      org: [],
    };
    this._configSubject.next(this._tenantConfig);
  }

  populateTenantConfig(data: any, isNotify: boolean = true): void {
    this._tenantConfig = new TenantPing();
    this._tenantConfig = {
      bankFees: data.bankFees,
      bigLogo: data.bigLogo,
      buyButtonLabel: data.buyButtonLabel,
      // calendarVisibility: data.calendarVisibility,
      cardFees: data.cardFees,
      cloudName: data.cloudName,
      csymbol: data.csymbol,
      customDomain: data.customDomain,
      email: data.email,
      phone: data.phone,
      environmentName: data.environmentName,
      isAddressNeededForEvent: data.isAddressNeededForEvent,
      // isDonationPublicCheckout: data.isDonationPublicCheckout,
      isNewTenant: data.isNewTenant,
      isRegAndPurchaseOnSamePage: data.isRegAndPurchaseOnSamePage,
      isShowCalenderView: data.isShowCalenderView,
      isShowCourses: data.isShowCourses,
      isShowDonation: data.isShowDonation,
      isShowScheduleMenu: data.isShowScheduleMenu,
      isShowStoreMenu: data.isShowStoreMenu,
      isSupportGrn: data.isSupportGrn,
      isShowRegistrationLink: data.isShowRegistrationLink,
      isShowRoomName: data.isShowRoomName,
      isShowSchedule: data.isShowSchedule,
      isWaiverFormToBeSigned: data.isWaiverFormToBeSigned,
      isMasterFranchise: data.isMasterFranchise,
      isMultiCountrySupport: data.isMultiCountrySupport,
      country: data.country,
      logo: data.logo,
      name: data.name,
      orgId: data.orgId,
      masterOrgId: data.masterOrgId,
      orgGuId: data.orgGuId,
      tenantId: data.tenantId,
      smallLogo: data.smallLogo,
      timezone: data.timezone,
      version: data.version,
      waiverFormLink: data.waiverFormLink,
      website: data.website,
      workshopLabel: data.workshopLabel,
      isAuthValid: data.isAuthValid,
      tenantAuthViewCmd: data.tenantAuthViewCmd,
      paymentProvider: data.paymentProvider,
      taxPercent: data.taxPercent,
      stripeApiKey: data.stripeApiKey,
      stripeAuthCode: data.stripeAuthCode,
      stripeClientId: data.stripeClientId,
      stripePublishableKey: data.stripePublishableKey,
      zoomApi: data.zoomApi,
      zoomAuthCode: data.zoomAuthCode,
      clanMeetingDomain: data.clanMeetingDomain,
      clanMeetingSecret: data.clanMeetingSecret,
      seoDescription: data.seoDescription,
      seoKeywords: data.seoKeywords,
      isShowSidebar: data.isShowSidebar,
      page1: data.page1,
      page2: data.page2,
      page3: data.page3,
      page4: data.page4,
      page5: data.page5,
      webstate: data.webstate,
      forms: data.forms,
      title: data.title,
      isExternalWebsite: data.isExternalWebsite,
      registrationLinkName: data.registrationLinkName,
      registerButtonLabel: data.registerButtonLabel,
      primaryGuardianLabel: data.primaryGuardianLabel,
      secondaryGuardianLabel: data.secondaryGuardianLabel,
      itemExpiresAfter: data.itemExpiresAfter,
      scheduleLabel: data.scheduleLabel,
      promotionLabel: data.promotionLabel,
      privacyPolicyLink: data.privacyPolicyLink,
      termsOfServiceLink: data.termsOfServiceLink,
      isEnableCourses: data.isEnableCourses,
      isEnableOnlineCourses: data.isEnableOnlineCourses,
      homepage: data.homepage,
      home: data.home,
      web: data.web,
      org: data.org,
      webRoutes: this.buildWebRoutes(data.webstate, data.web),
      socials: data.socials,
      address: data.address,
    };
    this._configSubject.next(this._tenantConfig);
    AppLoadService._cloudName = this._tenantConfig.cloudName;
  }

  private buildWebRoutes(webstate: string, web: any): any {
    const webRoutes = [];
    if (webstate === AppConstants.WEBSTATE_INT) {
      for (let i = 0; i < web.length; i++) {
        webRoutes.push('/' + web[i].name);
      }
    }
    return webRoutes;
  }

  getTenantPing(): TenantPing {
    return this._tenantConfig;
  }

  getOrgId(): string {
    return this._tenantConfig.orgId;
  }

  getOrgGuId(): string {
    return this._tenantConfig.orgGuId;
  }
  setHostId(hostId): void {
    this.hostId = hostId;
  }

  // isAuthTokenDomain(): boolean {
  //   let result = AppConstants.ALLOWED_COOKIE_SITES.find(item => this.hostName.includes(item));
  //   if (result === undefined) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  private getHostId(): string {
    const hostWithHttp = this.extractOutput(this.hostName);
    return hostWithHttp;
  }

  private extractOutput(hostId: string): string | null {
    // 1) Remove protocol if present
    let cleaned = hostId.replace(/^https?:\/\//, '');
  
    // 2) Remove anything after the first slash
    cleaned = cleaned.split('/')[0];
  
    // 3) Remove port if present
    cleaned = cleaned.split(':')[0];
  
    // 4) Check for known suffixes
    const knownSuffixes = ['.wajooba.me', '.onwajooba.com', '.wajooba.xyz'];
  
    if (knownSuffixes.some(suffix => cleaned.endsWith(suffix))) {
      // Split on '.' and return just the first part (the subdomain)
      const [subdomain] = cleaned.split('.');
      return subdomain;
    }
  
    // If no match, return the entire cleaned string or null (your choice)
    return cleaned;
  }
  
  // private extractOutput(hostId: string): string | null {
  //   const pattern = /^https?:\/\/((\*|[^./]+)\.)?(?!wajooba|onwajooba)([^/:]+\.[^./]+)(:\d+)?(\/|$)/;
  //   const match = hostId.match(pattern);

  //   if (match && match[1] !== '*') {
  //     const domain = match[2] ? `${match[2]}.${match[3]}` : match[3];
  //     if (domain.endsWith('.me.com') || domain.endsWith('.wajooba.xyz') || domain.endsWith('.onwajooba.com')) {
  //       return domain.split('.')[0];
  //     }
  //     return domain;
  //   }
  //   return null;
  // }

  public validatePublicUrlsInHostName(): boolean {
    const urlList = this.extractUrls();
    urlList.push('checkout');
    const url = new URL(this.hostName);
    const pathname = url.pathname;

    for (const uri of urlList) {
      if (pathname.includes(`/${uri}/`) || pathname.endsWith(`/${uri}`)) {
        return true;
      }
      if (AppUtilsService.isValidPublicRoute(pathname)) {
        return true;
      }
    }

    return false;
  }

  private extractUrls(): string[] {
    const web = this._tenantConfig.web;
    if (!web) {
      return [];
    }
    return web.map(page => page.url).filter(url => url !== '' && url !== AppConstants.LOGIN_ROUTE); // Exclude empty strings and "login"
  }

  public getHostOrigin(): string {
    return this.windowRef.getHostOrigin();
  }

  static getCloudName(): string {
    return this._cloudName;
  }
}
