import { AccountStage } from "./account-stage-model";
import { SalesStage } from "./sales-stage-model";
import { Claim } from "./claim-model";
import { from } from "rxjs";
export class ContactView {
  guId: string;
  name: string;
  activeMemberships: string;
  address: any;
  alertNote: string;
  balance: number;
  barcode: string;
  children: string;
  company: string;
  customFieldsVals: any;
  dateCreated: number;
  dateCreatedStr: number;
  dateOfBirth: number;
  description: string;
  email: string;
  emergencyContactName: string;
  emergencyContactPhone: number;
  enquiryNote: string;
  fullName: string;
  guardian: any;
  hasParent: boolean;
  hasSignedWaiverForm: boolean;
  id: string;
  imageUrl: string;
  interestedIn: string;
  isAdminVerified: boolean;
  isDuplicate: boolean;
  isEmailVerified: boolean;
  isLocalPicture: boolean;
  isNativeContact: boolean;
  isParent: boolean;
  isSendReminder: boolean;
  isSendSmsReminder: boolean;
  isStatusClient: boolean;
  isStatusFollowup: boolean;
  isStatusInActive: boolean;
  isStatusLead: boolean;
  isStatusMember: boolean;
  isStatusOther: boolean;
  isStatusStaff: boolean;
  isTeacher: boolean;
  isValidMailchimpContact: boolean;
  isWalkInUser: boolean;
  lastActiveMembership: string;
  lastName: string;
  latestNote: string;
  leadsource: string;
  mailchimpInfo: string;
  phone: number;
  picture: string;
  purchaseCount: string;
  referral: string;
  rolePermissions: string;
  roles: string;
  savedCards: string;
  siblingList: string;
  status: string;
  unPaidClasses: string;
  visitCount: number;
  salesStage: SalesStage;
  accountStage: AccountStage;
  claim: Claim;
  grnNumber: string;
  altEmail1: string;
  altEmail2: string;
  altEmail3: string;
  isMember: string;
  isLead: string;
  contactType: string[] = [];
  mailchimpStatus: any;
  constructor(contact: any) {
    this.name = contact.name;
    this.guId = contact.guId;
    this.activeMemberships = contact.activeMemberships;
    this.address = contact.address;
    this.alertNote = contact.alertNote;
    this.balance = contact.balance;
    this.barcode = contact.barcode;
    this.children = contact.children;
    this.company = contact.company;
    this.customFieldsVals = contact.customFieldsVals;
    this.dateCreated = contact.dateCreated;
    this.dateCreatedStr = contact.dateCreatedStr;
    this.dateOfBirth = contact.dateOfBirth;
    this.description = contact.description;
    this.email = contact.email;
    this.altEmail1 = contact.altEmail1;
    this.altEmail2 = contact.altEmail2;
    this.altEmail3 = contact.altEmail3;
    this.emergencyContactName = contact.emergencyContactName;
    this.emergencyContactPhone = contact.emergencyContactPhone;
    this.enquiryNote = contact.enquiryNote;
    this.fullName = contact.fullName;
    this.guardian = contact.guardian;
    this.hasParent = contact.hasParent;
    this.hasSignedWaiverForm = contact.hasSignedWaiverForm;
    this.id = contact.id;
    this.imageUrl = contact.imageUrl;
    this.interestedIn = contact.interestedIn;
    this.isAdminVerified = contact.isAdminVerified;
    this.isDuplicate = contact.isDuplicate;
    this.isEmailVerified = contact.isEmailVerified;
    this.isLocalPicture = contact.isLocalPicture;
    this.isNativeContact = contact.isNativeContact;
    this.isParent = contact.isParent;
    this.isSendReminder = contact.isSendReminder;
    this.isSendSmsReminder = contact.isSendSmsReminder;
    this.isStatusClient = contact.isStatusClient;
    this.isStatusFollowup = contact.isStatusFollowup;
    this.isStatusInActive = contact.isStatusInActive;
    this.isStatusLead = contact.isStatusLead;
    this.isStatusMember = contact.isStatusMember;
    this.isStatusOther = contact.isStatusOther;
    this.isStatusStaff = contact.isStatusStaff;
    this.isTeacher = contact.isTeacher;
    this.isValidMailchimpContact = contact.isValidMailchimpContact;
    this.isWalkInUser = contact.isWalkInUser;
    this.lastActiveMembership = contact.lastActiveMembership;
    this.lastName = contact.lastName;
    this.latestNote = contact.latestNote;
    this.leadsource = contact.leadsource;
    this.mailchimpInfo = contact.mailchimpInfo;
    this.phone = contact.phone;
    this.picture = contact.picture;
    this.purchaseCount = contact.purchaseCount;
    this.referral = contact.referral;
    this.rolePermissions = contact.rolePermissions;
    this.roles = contact.roles;
    this.savedCards = contact.savedCards;
    this.siblingList = contact.siblingList;
    this.status = contact.status;
    this.unPaidClasses = contact.unPaidClasses;
    this.visitCount = contact.visitCount;
    this.salesStage = contact.salesStage;
    this.accountStage = contact.accountStage;
    this.claim = contact.claim;
    this.grnNumber = contact.grnNumber;
    this.isMember = contact.isMember;
    this.isLead = contact.isLead;
    this.contactType = contact.contactType;
    this.mailchimpStatus = contact.mailchimpStatus;
  }
}
